import { useState } from 'react';

import { OpenWithTypes } from '@/components/forms/login-signup-form';
import Button from '@/components/global/button';
import { LoginSignupModal } from '@/components/modals/login-signup-modal';
import cn from '@/lib/cn';

interface SignupLoginButtonModalProps {
    className?: string;
    buttonClassName?: string;
    tabIndex?: number;
}

const SignupLoginButtonModal = ({ buttonClassName, className, tabIndex }: SignupLoginButtonModalProps) => {
    const [showLoginModal, setShowLoginModalState] = useState<boolean>(false);
    const [modalType, setModalType] = useState<OpenWithTypes>(OpenWithTypes.SIGNUP);
    const openLoginModal = (modalType: OpenWithTypes) => {
        setModalType(modalType);
        setShowLoginModalState(true);
    };
    const closeLoginModal = () => setShowLoginModalState(false);

    return (
        <div className={className}>
            <div className="flex items-center">
                <Button
                    tabIndex={tabIndex}
                    type="action"
                    onClick={() => openLoginModal(OpenWithTypes.LOGIN)}
                    color="transparent"
                    className={cn(
                        'rounded-full border border-analyst-gray px-4 py-2 font-brand-md text-analyst-black hover:bg-analyst-lavender transition-colors bg-white bg-opacity-60',
                        buttonClassName
                    )}
                >
                    Sign Up or Login
                </Button>
            </div>
            <LoginSignupModal
                closeFn={closeLoginModal}
                isOpen={showLoginModal}
                openWith={modalType}
            />
        </div>
    );
};

export default SignupLoginButtonModal;
