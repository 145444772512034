import { useRef, useState } from 'react';

import Icon from '@/components/global/icon';
import useOutsideClick from '@/helpers/hooks/useOutsideClick';
import cn from '@/lib/cn';

interface DropdownMenuProps {
    className?: string;
    menuClassName?: string;
    toggleClassName?: string;
    trigger: React.ReactNode;
    menuItems?: React.ReactNode;
}

export const DropdownMenu = ({ className, menuClassName, trigger, menuItems, toggleClassName }: DropdownMenuProps) => {
    const dropdownRef = useRef(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const hideDropdown = () => setIsDropdownVisible(false);
    const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);

    useOutsideClick(dropdownRef, hideDropdown);

    return (
        <div
            ref={dropdownRef}
            className={cn('relative', className)}
        >
            <div
                className="flex items-center gap-3 hover:cursor-pointer group"
                onClick={toggleDropdown}
            >
                {trigger}{' '}
                <Icon
                    type="caret"
                    color="analystPurple"
                    size="xxs"
                    iconClassName="w-2"
                    className={cn(
                        'flex items-center justify-center origin-center transition-transform rotate-90 group-hover:text-white w-2',
                        {
                            '-rotate-90 origin-center transition-transform': isDropdownVisible,
                        },
                        toggleClassName
                    )}
                />
            </div>

            <menu
                className={cn(
                    'relative bg-analyst-white shadow-md border border-brand-gray-med md:absolute md:pl-4 md:p-4 pb-4 md:top-14 md:w-44 md:left-0 md:z-20 md:rounded-lg flex flex-col',
                    menuClassName,
                    {
                        'opacity-0 hidden transition-opacity -z-10': !isDropdownVisible,
                        'opacity-100 visible transition-opacity z-10': isDropdownVisible,
                    }
                )}
            >
                {menuItems}
            </menu>
        </div>
    );
};
