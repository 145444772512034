import { gql } from 'graphql-request';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { Bold, Heading, Paragraph } from '@/components/dom/text-elements';
import { LoginForm } from '@/components/forms/login-form';
import { OTPForm } from '@/components/forms/otp-form';
import { SignupForm } from '@/components/forms/signup-form';
import Button from '@/components/global/button';
import HighlightedText from '@/components/ui/HighlightedText';
import { GQL_CLIENT } from '@/lib/graphql';
import { CurrentUserType } from '@/queries/current-user';

const RESEND_OTP_QUERY = gql`
    mutation ResendLoginAttempt($loginAttemptId: ID!) {
        resendLoginAttempt(input: { loginAttemptId: $loginAttemptId }) {
            __typename
            ... on SendLoginAttemptSuccess {
                loginAttemptId
            }
            ... on Error {
                message
            }
        }
    }
`;

export enum OpenWithTypes {
    LOGIN = 'log-in',
    SIGNUP = 'sign-up',
    OTP = 'otp',
}

interface LoginSignupFormProps {
    logInCopy?: React.ReactNode;
    openWith?: OpenWithTypes;
    signUpCopy?: React.ReactNode;
    onSuccess?: (user: CurrentUserType) => void;
}

interface LoginContentProps {
    logInCopy?: React.ReactNode;
    onSuccess: (loginAttemptId: string, email: string) => void;
    showSignUpHandler: () => void;
}

const LoginContent = ({ logInCopy, onSuccess, showSignUpHandler }: LoginContentProps) => {
    return (
        <div className="flex flex-col justify-center gap-y-6 animate-fadeIn">
            {/* <Heading
                importance={4}
                className="text-3xl mb-4 text-center"
            >
                Welcome to <HighlightedText>Thematic</HighlightedText>
            </Heading> */}

            {logInCopy ? (
                logInCopy
            ) : (
                <Paragraph className="font-brand-md mb-2 text-center text-analyst-darker-gray">
                    Please enter the email address you used to sign up for Thematic. We&apos;ll email you a one&#45;time
                    passcode to log in.
                </Paragraph>
            )}
            <Paragraph className="mb-4 text-center">
                Don&apos;t have an account yet?&nbsp;
                <Button
                    type="action"
                    color="transparent"
                    onClick={showSignUpHandler}
                    className="text-analyst-blue hover:text-analyst-dark-lavender transition-colors text-base font-brand-md inline-block p-0"
                >
                    Create one
                </Button>
            </Paragraph>

            <LoginForm
                onSuccess={onSuccess}
                className="w-full"
            />
        </div>
    );
};
interface SignupContentProps {
    disableLogin?: boolean;
    signUpCopy?: React.ReactNode;
    onSuccess: (loginAttemptId: string, email: string) => void;
    showLoginHandler: () => void;
}

const SignupContent = ({ disableLogin, signUpCopy, onSuccess, showLoginHandler }: SignupContentProps) => {
    return (
        <div className="flex flex-col justify-center gap-y-6 animate-fadeIn">
            {signUpCopy ? (
                signUpCopy
            ) : (
                <Paragraph className="font-brand-md mb-2 text-center text-analyst-darker-gray">
                    Enter your details below to create a Thematic account. We&apos;ll email you a one&#45;time passcode
                    to sign in &ndash; no passwords to remember!
                </Paragraph>
            )}
            {!disableLogin && (
                <Paragraph className="text-center">
                    Already have an account?{' '}
                    <Button
                        type="action"
                        color="transparent"
                        onClick={showLoginHandler}
                        className="text-analyst-blue hover:text-analyst-dark-lavender transition-colors text-base font-brand-md inline-block p-0"
                    >
                        Log in
                    </Button>
                </Paragraph>
            )}

            <SignupForm onSuccess={onSuccess} />
        </div>
    );
};

interface OTPContentProps {
    email: string;
    loginAttemptId: string;
    onLoginSuccess?: (user: CurrentUserType) => void;
    submitButtonCopy: string;
    showLoginHandler: () => void;
}

const OTPContent = ({ email, loginAttemptId, onLoginSuccess, submitButtonCopy, showLoginHandler }: OTPContentProps) => {
    const resendOtp = async () => {
        const variables = { loginAttemptId };

        const data: {
            resendLoginAttempt:
                | { __typename: 'SendLoginAttemptSuccess'; loginAttemptId: string }
                | { __typename: 'Error'; message: string };
        } = await GQL_CLIENT.request(RESEND_OTP_QUERY, variables);
        const result = data.resendLoginAttempt;
        const success = result.__typename === 'SendLoginAttemptSuccess';

        if (success) {
            toast.success('Your one-time passcode was resent!', {
                style: {
                    zIndex: 10000,
                },
            });
        } else {
            toast.error(result.message);
        }
    };

    return (
        <div className="flex flex-col justify-center gap-y-4 animate-fadeIn">
            <Heading importance={5}>Your one-time passcode was sent</Heading>
            <Paragraph className="mb-2">
                Check your email (<Bold>{email.toLowerCase()}</Bold>) for your one-time passcode. The passcode expires
                in 15 minutes. If you didn&apos;t receive it, please verify your email address and check your spam
                folder.{' '}
            </Paragraph>
            <Paragraph className="mb-2">
                Problems?{' '}
                <Button
                    type="action"
                    color="transparent"
                    onClick={resendOtp}
                    className="text-analyst-blue hover:text-analyst-dark-lavender transition-colors text-base font-brand-md inline-block p-0"
                >
                    Resend passcode
                </Button>
                &nbsp;or&nbsp;
                <Button
                    type="action"
                    color="transparent"
                    onClick={showLoginHandler}
                    className="text-analyst-blue hover:text-analyst-dark-lavender transition-colors text-base font-brand-md inline-block p-0"
                >
                    Re-enter your email
                </Button>
                .
            </Paragraph>

            <Paragraph>
                Already have an account?{' '}
                <Button
                    type="action"
                    color="transparent"
                    onClick={showLoginHandler}
                    className="text-analyst-blue hover:text-analyst-dark-lavender transition-colors text-base font-brand-md inline-block p-0"
                >
                    Log in
                </Button>
            </Paragraph>

            <OTPForm
                loginAttemptId={loginAttemptId}
                onLoginSuccess={onLoginSuccess}
                submitButtonCopy={submitButtonCopy}
            />
        </div>
    );
};

export const LoginSignupForm = ({
    logInCopy,
    openWith = OpenWithTypes.LOGIN,
    signUpCopy,
    onSuccess,
}: LoginSignupFormProps) => {
    const [showLoginForm, setShowLoginFormState] = useState(openWith === OpenWithTypes.LOGIN);
    const [showSignupForm, setShowSignupFormState] = useState(openWith === OpenWithTypes.SIGNUP);
    const [showOTPForm, setShowOTPForm] = useState(openWith === OpenWithTypes.OTP);
    const [email, setEmail] = useState('');
    const [loginAttemptId, setLoginAttemptId] = useState('');
    const onLoginSignUpSuccess = (loginAttemptId: string, email: string) => {
        showOTPHandler();
        setEmail(email);
        setLoginAttemptId(loginAttemptId);
    };
    const showSignUpHandler = () => {
        setShowSignupFormState(true);
        setShowLoginFormState(false);
        setShowOTPForm(false);
    };
    const showLoginHandler = () => {
        setShowLoginFormState(true);
        setShowOTPForm(false);
        setShowSignupFormState(false);
    };
    const showOTPHandler = () => {
        setShowOTPForm(true);
        setShowLoginFormState(false);
        setShowSignupFormState(false);
    };

    return (
        <div className="flex flex-col justify-center gap-y-6">
            <Heading
                importance={4}
                className="text-3xl mb-4 text-center"
            >
                Welcome to <HighlightedText>Thematic</HighlightedText>
            </Heading>

            <div className="">
                {showSignupForm && (
                    <SignupContent
                        signUpCopy={signUpCopy}
                        onSuccess={onLoginSignUpSuccess}
                        showLoginHandler={showLoginHandler}
                    />
                )}

                {showLoginForm && (
                    <LoginContent
                        logInCopy={logInCopy}
                        onSuccess={onLoginSignUpSuccess}
                        showSignUpHandler={showSignUpHandler}
                    />
                )}

                {showOTPForm && (
                    <OTPContent
                        email={email}
                        loginAttemptId={loginAttemptId}
                        onLoginSuccess={onSuccess}
                        submitButtonCopy={showLoginForm ? 'Log In' : 'Sign Up'}
                        showLoginHandler={showLoginHandler}
                    />
                )}
            </div>
        </div>
    );
};
