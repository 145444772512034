'use client';

import { usePathname } from 'next/navigation';
import React, { createContext, RefObject, useContext, useRef, useState } from 'react';

import { CurrentOrganizationSelector } from '@/components/global/current-organization-selector';
import MainFooter from '@/components/global/main-footer';
import { MainHeaderWithIntersection } from '@/components/global/main-header';
import { PRODUCT_PAGES } from '@/lib/constants';

interface MainContentContextValue {
    intersectionRef: RefObject<HTMLElement>;
    updateIntersectionRef: (ref: RefObject<HTMLElement>) => void;
}

export const MainContentContext = createContext({} as MainContentContextValue);

export const MainContentProvider = ({ children }: { children: React.ReactNode }) => {
    const startRef = useRef<HTMLDivElement>(null);
    const [intersectionRef, setIntersectionRef] = useState<RefObject<HTMLElement>>(startRef);
    const updateIntersectionRef = (ref: RefObject<HTMLElement>) => setIntersectionRef(ref);
    const value: MainContentContextValue = { intersectionRef, updateIntersectionRef };

    return (
        <MainContentContext.Provider value={value}>
            {children}
            <div ref={startRef} />
        </MainContentContext.Provider>
    );
};

export const useMainContentContext = () => {
    const context = useContext(MainContentContext);

    if (!context) {
        throw new Error('useMainContentContext must be used within a MainContentProvider');
    }

    return context;
};

export const MainContent = ({ children }: { children: React.ReactNode }) => {
    const pathname = usePathname();
    const isProductPage = PRODUCT_PAGES.some((productPath) => pathname?.includes(productPath));

    return (
        <>
            <CurrentOrganizationSelector />

            {!isProductPage && (
                <MainHeaderWithIntersection
                    condenseLogoOnScroll={true}
                    className="top-5 animate-fadeIn"
                />
            )}

            {children}

            <MainFooter />

            <div id="root" />
        </>
    );
};
