import { useRouter } from 'next/navigation';
import { useState } from 'react';

import { UserAvatar } from '@/components/global/avatar';
import { useCurrentOrganization } from '@/components/global/current-organization';
import { useGlobalState } from '@/components/global/global-state';
import Icon from '@/components/global/icon';
import SiteLink, { DEFAULT_SITE_LINK_STYLING } from '@/components/global/site-link';
import { logOutCurrentUser } from '@/helpers/logout';
import cn from '@/lib/cn';

const LINK_CLASSES =
    'text-analyst-gray font-brand-md visited:text-analyst-gray visited:underline hover:text-analyst-purple transition-colors';

interface UserProfileDropdownProps {
    className?: string;
    menuClassName?: string;
    toggleClassName?: string;
    hideLogout?: boolean;
}

const UserProfileDropdown = ({
    className,
    menuClassName,
    toggleClassName,
    hideLogout = false,
}: UserProfileDropdownProps) => {
    const router = useRouter();
    const { globalState, setGlobalState } = useGlobalState();
    const [showDropdown, setShowDropdown] = useState(false);
    const toggleDropdown = () => setShowDropdown(!showDropdown);
    const { currentOrganizationIndicesUrl, currentOrganizationMembersUrl, currentOrganizationProfileUrl } =
        useCurrentOrganization();

    return (
        <div className={cn('hover:cursor-pointer', className)}>
            <div className="relative">
                <div
                    className="flex items-center gap-4"
                    onClick={toggleDropdown}
                >
                    <UserAvatar
                        url={globalState.currentUser?.avatarUrl || ''}
                        name={globalState.currentUser?.name || ''}
                        className="w-10 h-10 mb-0 text-base rounded-full overflow-hidden"
                    />

                    <Icon
                        type="caret"
                        color="analystPurple"
                        size="xxs"
                        className={cn(
                            'transform origin-center transition-transform rotate-90',
                            {
                                '-rotate-90 origin-center transition-transform': showDropdown,
                            },
                            toggleClassName
                        )}
                    />
                </div>
                {showDropdown && (
                    <menu
                        className={cn(
                            'relative bg-analyst-white shadow-md border border-brand-gray-med md:absolute md:pl-4 md:p-4 pb-4 md:top-14 md:w-44 md:right-0 md:rounded-lg flex flex-col animate-fadeIn',
                            menuClassName
                        )}
                    >
                        {currentOrganizationIndicesUrl ? (
                            <SiteLink
                                key="indices-link"
                                url={currentOrganizationIndicesUrl}
                                className={LINK_CLASSES}
                            >
                                Dashboard
                            </SiteLink>
                        ) : null}
                        {currentOrganizationMembersUrl ? (
                            <SiteLink
                                key="members-link"
                                url={currentOrganizationMembersUrl}
                                className={LINK_CLASSES}
                            >
                                Team members
                            </SiteLink>
                        ) : null}
                        {currentOrganizationProfileUrl ? (
                            <SiteLink
                                key="profile-link"
                                url={currentOrganizationProfileUrl}
                                className={LINK_CLASSES}
                            >
                                Team profile
                            </SiteLink>
                        ) : null}
                        <SiteLink
                            key="account-link"
                            url="/my-account/profile"
                            className={LINK_CLASSES}
                        >
                            My account
                        </SiteLink>
                        {!hideLogout && (
                            <button
                                onClick={() => logOutCurrentUser({ onSuccess: router.refresh, setGlobalState })}
                                className={cn(DEFAULT_SITE_LINK_STYLING, LINK_CLASSES)}
                                key="logout"
                            >
                                Log out
                            </button>
                        )}
                    </menu>
                )}
            </div>
        </div>
    );
};

export default UserProfileDropdown;
