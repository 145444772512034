'use client';

import { useRouter } from 'next/navigation';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntersection } from 'react-use';

import { Paragraph } from '@/components/dom/text-elements';
import Button from '@/components/global/button';
import { useGlobalState } from '@/components/global/global-state';
import Icon from '@/components/global/icon';
import { NavigationLink } from '@/components/global/link';
import SignupLoginButtonModal from '@/components/global/signup-login-button-modal';
import TabNavigation, {
    type TabLink,
    type TabNavigationLink,
    type TabSubMenu,
    type TabSubMenuLink,
} from '@/components/global/tab-navigation';
import ThematicLogoLink from '@/components/global/thematic-logo-link';
import { TryForYourselfLinkButton } from '@/components/global/try-for-yourself-cta';
import UserProfileDropdown from '@/components/global/user-profile-dropdown';
import { useMainContentContext } from '@/components/pages/GlobalMainContent';
import IconButton from '@/components/ui/IconButton';
import { logOutCurrentUser } from '@/helpers/logout';
import cn from '@/lib/cn';
import { PRODUCT_NAV_LINKS } from '@/lib/layout';

const productSubMenu = PRODUCT_NAV_LINKS.map(({ group }) => {
    return {
        label: group?.label,
        subMenu: group?.links?.map(({ label, href }) => ({
            href,
            label,
        })),
    };
});
const mainNavTabs = [
    {
        label: 'Product',
        subMenu: [
            {
                subMenu: [
                    { href: '/product', label: 'Overview' },
                    { href: '/product/asset-management-product-development', label: 'Develop asset-backed products' },
                ],
            },
            ...productSubMenu,
        ],
        tabIndex: 2,
    },
    {
        href: '/about-us',
        label: 'About Us',
        tabIndex: 3,
    },
    {
        href: '/blog',
        label: 'Blog',
        tabIndex: 5,
    },
];

interface BaseHeaderProps {
    className?: string;
    hideNavigation?: boolean;
}

type HeaderWithIntersectionProps = BaseHeaderProps & {
    intersectionClassName?: string;
    condenseLogoOnScroll?: boolean;
};

interface MobileNavProps {
    id: string;
    tabs: Array<TabNavigationLink>;
}

interface MobileAccordionMenuProps {
    label?: React.ReactNode;
    subMenuItems: Array<TabSubMenu>;
}

const MobileAccordionMenu = ({ label, subMenuItems }: MobileAccordionMenuProps) => {
    const [isOpen, setIsOpenState] = useState(false);
    const toggleDropdown = () => setIsOpenState(!isOpen);

    return (
        <div className={cn('flex flex-col gap-y-3')}>
            <div
                className="flex items-center gap-x-4 hover:cursor-pointer group"
                onClick={toggleDropdown}
            >
                <span
                    className={cn(
                        'text-analyst-lavender visited:text-analyst-white group-hover:text-analyst-white transition-colors'
                    )}
                >
                    {label}
                </span>
                <Icon
                    type="caret"
                    color="analystLavender"
                    size="xxs"
                    className={cn(
                        'group-hover:text-analyst-white transform origin-center transition-transform rotate-90',
                        {
                            '-rotate-90 origin-center transition-transform': isOpen,
                        }
                    )}
                />
            </div>

            {subMenuItems.map(({ label: groupHeading, subMenu }, subMenuIndex: number) => (
                <div
                    key={`mobile-accordion-submenu-${subMenuIndex}`}
                    className={cn('flex flex-col gap-y-2', {
                        'opacity-0 hidden transition-opacity -z-10': !isOpen,
                        'opacity-100 visible transition-opacity z-10': isOpen,
                    })}
                >
                    {groupHeading && (
                        <Paragraph className={cn('pl-4 mb-0 text-analyst-lavender-medium font-brand-md')}>
                            {groupHeading}
                        </Paragraph>
                    )}

                    {subMenu.length > 0 && (
                        <ul
                            className={cn('flex flex-col gap-2 pl-8', {
                                'pl-4': !groupHeading,
                            })}
                        >
                            {subMenu.map(
                                ({ label: linkLabel, href, tabIndex, className }, subMenuItemIndex: number) => (
                                    <li key={`tab-navigation-sub-menu-link-${subMenuItemIndex}`}>
                                        <NavigationLink
                                            tabIndex={tabIndex}
                                            className={cn(
                                                'text-analyst-lavender visited:text-analyst-lavender hover:text-analyst-white transition-colors',
                                                className
                                            )}
                                            href={href}
                                        >
                                            {linkLabel}
                                        </NavigationLink>
                                    </li>
                                )
                            )}
                        </ul>
                    )}
                </div>
            ))}
        </div>
    );
};

const MobileNav = ({ id, tabs }: MobileNavProps) => {
    const router = useRouter();
    const { globalState, setGlobalState } = useGlobalState();
    const [showNavigation, setShowNavigation] = useState(false);
    const toggleNavigation = () => setShowNavigation(!showNavigation);
    const closeNavigation = () => setShowNavigation(false);

    return (
        <menu className="relative w-full max-w-sm mx-auto md:hidden">
            <TabNavigation
                id="main-nav"
                className="justify-between items-center w-full"
                startCTA={
                    <ThematicLogoLink
                        className="mr-8"
                        tabIndex={1}
                    />
                }
                endCTA={
                    <IconButton
                        iconProps={{
                            className: 'w-6 h-full text-white flex items-center justify-center',
                            iconClassName: 'w-full',
                        }}
                        onClick={toggleNavigation}
                        onTouchEnd={toggleNavigation}
                        iconType="hamburgerMenu"
                        tabIndex={2}
                    />
                }
                tabs={[]}
            />

            <aside
                className={cn(
                    'fixed top-0 right-0 z-50 w-full bg-analyst-black h-full p-6 translate-x-full transition-transform duration-300 flex flex-col items-start',
                    {
                        'translate-x-0 transition-transform duration-300': showNavigation,
                    }
                )}
            >
                <header className="flex items-center justify-between w-full mb-10">
                    <ThematicLogoLink
                        condenseLogo={true}
                        tabIndex={1}
                        className="w-4"
                    />
                    <IconButton
                        iconProps={{
                            className: 'w-5 h-full text-white flex items-center justify-center',
                            iconClassName: 'w-full',
                        }}
                        className="self-end"
                        onClick={closeNavigation}
                        onTouchEnd={closeNavigation}
                        iconType="close"
                        tabIndex={2}
                    />
                </header>

                {globalState.currentUser && (
                    <UserProfileDropdown
                        className={cn('opacity-100 mb-10 md:mb-0 md:hidden lg:opacity-0')}
                        menuClassName="absolute top-full translate-y-4 w-48 left-0 rounded-lg"
                        toggleClassName="text-white"
                        hideLogout={true}
                    />
                )}

                <nav className="flex flex-col gap-4 items-start mb-10 w-full">
                    {tabs.map((tabProps, index: number) => {
                        if ((tabProps as TabSubMenuLink).subMenu) {
                            const tabSubMenuLinkProps = tabProps as TabSubMenuLink;
                            return (
                                <MobileAccordionMenu
                                    key={`tab-navigation-link-${id}=${index}`}
                                    label={tabSubMenuLinkProps.label}
                                    subMenuItems={tabSubMenuLinkProps.subMenu as Array<TabSubMenu>}
                                />
                            );
                        }
                        const tabNavLinkProps = tabProps as TabLink;

                        return (
                            <NavigationLink
                                key={`tab-navigation-link-${id}=${index}`}
                                tabIndex={tabNavLinkProps.tabIndex}
                                className={cn(
                                    'text-analyst-lavender visited:text-analyst-lavender hover:text-analyst-white transition-colors',
                                    tabNavLinkProps.className
                                )}
                                href={tabNavLinkProps.href}
                            >
                                {tabNavLinkProps.label}
                            </NavigationLink>
                        );
                    })}

                    <TryForYourselfLinkButton
                        className="w-full mt-4"
                        tabIndex={7}
                    />
                </nav>

                <footer className="flex flex-col gap-4 items-start mt-auto  w-full">
                    {globalState.currentUser ? (
                        <Button
                            type="action"
                            color="transparent"
                            className={cn(
                                'rounded-full border border-analyst-gray px-4 py-2 font-brand-md text-analyst-black hover:bg-analyst-lavender transition-colors bg-white w-full'
                            )}
                            onClick={() => logOutCurrentUser({ onSuccess: router.refresh, setGlobalState })}
                            key="logout"
                        >
                            Log out
                        </Button>
                    ) : (
                        <SignupLoginButtonModal
                            tabIndex={9}
                            className="w-full"
                            buttonClassName="w-full bg-opacity-100"
                        />
                    )}
                </footer>
            </aside>
        </menu>
    );
};

export const MainHeader = ({ className, hideNavigation = false }: BaseHeaderProps) => {
    const { globalState } = useGlobalState();

    return (
        <header
            className={cn('flex items-center justify-center top-0 left-0 z-[100] px-10 pt-5 fixed w-full', className)}
        >
            {hideNavigation && (
                <div className="bg-analyst-black px-5 py-2.5 rounded-full flex items-center">
                    <ThematicLogoLink tabIndex={1} />
                </div>
            )}
            {!hideNavigation && (
                <>
                    <MobileNav
                        id="main-mobile-nav"
                        tabs={mainNavTabs as Array<TabNavigationLink>}
                    />
                    <TabNavigation
                        id="main-nav"
                        className="justify-between hidden md:flex"
                        startCTA={
                            <ThematicLogoLink
                                className="mr-6"
                                tabIndex={1}
                            />
                        }
                        endCTA={
                            <TryForYourselfLinkButton
                                className="ml-8"
                                tabIndex={7}
                            />
                        }
                        tabs={mainNavTabs as Array<TabNavigationLink>}
                    />
                </>
            )}

            {globalState.currentUser ? (
                <UserProfileDropdown
                    className={cn(
                        'hidden opacity-0 md:block lg:opacity-100 md:absolute md:top-1/2 md:z-10 md:-translate-y-1/2 md:last:mt-2.5 md:right-10'
                    )}
                />
            ) : (
                <SignupLoginButtonModal
                    tabIndex={9}
                    className={cn(
                        'hidden opacity-0 md:block lg:opacity-100 md:absolute md:top-1/2 md:z-10 md:-translate-y-1/2 md:last:mt-2.5 md:right-10'
                    )}
                />
            )}
        </header>
    );
};

export const MainHeaderWithIntersection = ({ condenseLogoOnScroll, className }: HeaderWithIntersectionProps) => {
    const { intersectionRef } = useMainContentContext();
    const { globalState } = useGlobalState();
    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    });
    const [reachedIntersection, setReachedIntersectionState] = useState(false);

    const updateReachedIntersection = useCallback(() => {
        const yPos = intersectionRef.current?.getBoundingClientRect().y || 0;

        if (yPos <= 0 && !reachedIntersection) {
            setReachedIntersectionState(true);
        } else if (yPos > 0 && reachedIntersection) {
            setReachedIntersectionState(false);
        }
    }, [intersectionRef, reachedIntersection]);

    useEffect(() => {
        intersection && updateReachedIntersection();
    }, [intersection, updateReachedIntersection]);

    return (
        <header
            className={cn(
                'flex items-center justify-center top-0 left-0 z-[100] px-10 pt-5 fixed w-full',
                { 'pt-0 transition-all': reachedIntersection },
                className
            )}
        >
            <MobileNav
                id="main-mobile-nav"
                tabs={mainNavTabs as Array<TabNavigationLink>}
            />
            <TabNavigation
                id="main-nav"
                className="justify-between hidden md:flex"
                startCTA={
                    <ThematicLogoLink
                        condenseLogo={condenseLogoOnScroll && reachedIntersection}
                        className="mr-6"
                        tabIndex={1}
                    />
                }
                endCTA={
                    <TryForYourselfLinkButton
                        className="ml-8"
                        tabIndex={7}
                    />
                }
                tabs={mainNavTabs as Array<TabNavigationLink>}
            />

            {globalState.currentUser ? (
                <UserProfileDropdown
                    className={cn(
                        'hidden opacity-0 md:block lg:opacity-100 md:absolute md:top-1/2 md:z-10 md:-translate-y-1/2 md:last:mt-2.5 md:right-10'
                    )}
                />
            ) : (
                <SignupLoginButtonModal
                    tabIndex={9}
                    className={cn(
                        'hidden opacity-0 md:block lg:opacity-100 md:absolute md:top-1/2 md:z-10 md:-translate-y-1/2 md:last:mt-2.5 md:right-10'
                    )}
                />
            )}
        </header>
    );
};

export default MainHeader;
