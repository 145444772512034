import { IconMap } from '@/components/global/icon';

export interface NavLinkProps {
    icon?: keyof typeof IconMap;
    label: string;
    href: string;
    onClick?: () => void;
    className?: string;
}

export type NavLinkGroupType = Partial<NavLinkProps> & {
    group?: {
        label: string;
        links?: Array<NavLinkProps>;
    };
};

export type NavLinksListType = Array<NavLinkGroupType>;
export const PRODUCT_NAV_LINKS: NavLinksListType = [
    {
        group: {
            label: 'Equity research',
            links: [
                {
                    href: '/analyst',
                    label: 'Research a topic',
                },
                {
                    href: '/analyst',
                    label: 'Stock screener',
                },
                {
                    href: '/product/evaluation',
                    label: 'Find companies',
                },
            ],
        },
    },
    {
        group: {
            label: 'Build a portfolio or index',
            links: [
                {
                    href: '/index-builder/create',
                    label: 'Find companies',
                },
                {
                    href: '/index-builder/create',
                    label: 'Load an existing portfolio',
                },
                {
                    href: '/index-builder/create/build',
                    label: 'Backtest',
                },
            ],
        },
    },
];
export const NAV_LINKS: NavLinksListType = [
    ...PRODUCT_NAV_LINKS,
    {
        group: {
            label: 'Learn more',
            links: [
                {
                    href: '/',
                    label: 'What is Thematic?',
                },
                {
                    href: '/product',
                    label: 'Product',
                },
                {
                    href: '/about-us',
                    label: 'About us',
                },
                {
                    href: '/blog',
                    label: 'Blog',
                },
            ],
        },
    },
];
