import { forwardRef, Ref } from 'react';

import { Paragraph } from '@/components/dom/text-elements';
import { NavigationLink } from '@/components/global/link';
import { DropdownMenu } from '@/components/ui/dropdown-menu';
import cn from '@/lib/cn';

export interface MainTabLinkProps {
    label: React.ReactNode;
    tabIndex?: number;
    className?: string;
    title?: string;
}

export interface TabSubMenu {
    label?: React.ReactNode;
    subMenu: Array<TabLink>;
}

export type TabSubMenuLink = MainTabLinkProps & {
    onClick?: () => void;
    subMenu?: Array<TabSubMenu>;
};

export type TabLink = MainTabLinkProps & {
    href: string;
};

export type TabNavigationLink = TabLink | TabSubMenuLink;

export interface TabNavigationProps {
    tabs: Array<TabNavigationLink>;
    className?: string;
    id: string;
    startCTA?: React.ReactNode;
    endCTA?: React.ReactNode;
}

const SubMenu = ({ subMenuItems }: { subMenuItems: Array<TabSubMenu> }) => {
    return (
        <div className={cn('flex flex-col gap-y-3')}>
            {subMenuItems.map(({ label, subMenu }, subMenuIndex: number) => (
                <div
                    key={`sub-menu-${subMenuIndex}`}
                    className="flex flex-col gap-2"
                >
                    {label && <Paragraph className="mb-0 text-analyst-black font-brand-md">{label}</Paragraph>}

                    {subMenu.length > 0 && (
                        <ul
                            className={cn('flex flex-col gap-2', {
                                'pl-4': label,
                            })}
                        >
                            {subMenu.map(({ label, href, tabIndex, className }, subMenuItemIndex: number) => (
                                <li key={`tab-navigation-sub-menu-link-${subMenuItemIndex}`}>
                                    <NavigationLink
                                        tabIndex={tabIndex}
                                        className={cn(
                                            ' text-analyst-blue visited:text-analyst-dark-lavender hover:text-analyst-dark-lavender transition-colors',
                                            className
                                        )}
                                        href={href}
                                    >
                                        {label}
                                    </NavigationLink>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ))}
        </div>
    );
};

const TabNavigation = forwardRef(function TabNavigation(
    { startCTA: StartCTA, endCTA: EndCTA, id, className, tabs = [] }: TabNavigationProps,
    ref: Ref<HTMLElement>
) {
    return (
        <nav
            ref={ref}
            id={id}
            className={cn('bg-analyst-black px-5 py-2.5 rounded-full flex items-center', className)}
        >
            {StartCTA}

            <div className="flex items-center gap-5">
                {tabs.map((tabProps, index: number) => {
                    if ((tabProps as TabSubMenuLink).subMenu) {
                        const tabSubMenuLinkProps = tabProps as TabSubMenuLink;

                        return (
                            <DropdownMenu
                                key={`tab-navigation-link-${id}=${index}`}
                                menuItems={<SubMenu subMenuItems={tabSubMenuLinkProps.subMenu as Array<TabSubMenu>} />}
                                toggleClassName="group-hover:text-analyst-white text-analyst-lavender transition-colors"
                                menuClassName="md:w-64 md:top-12"
                                trigger={
                                    <span
                                        tabIndex={tabSubMenuLinkProps.tabIndex}
                                        className={cn(
                                            'text-analyst-lavender visited:text-analyst-white hover:text-analyst-white transition-colors',
                                            className
                                        )}
                                    >
                                        {tabSubMenuLinkProps.label}
                                    </span>
                                }
                            />
                        );
                    }

                    const tabNavLinkProps = tabProps as TabLink;

                    return (
                        <NavigationLink
                            key={`tab-navigation-link-${id}=${index}`}
                            tabIndex={tabNavLinkProps.tabIndex}
                            className={cn(
                                'text-analyst-lavender visited:text-analyst-white hover:text-analyst-white transition-colors',
                                className
                            )}
                            href={tabNavLinkProps.href}
                        >
                            {tabNavLinkProps.label}
                        </NavigationLink>
                    );
                })}
            </div>
            {EndCTA}
        </nav>
    );
});

export default TabNavigation;
