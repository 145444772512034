import Link from 'next/link';
import { useRouter } from 'next/router';

import { Cube } from '@/components/global/logo';
import cn from '@/lib/cn';

const AdminNavigation = () => {
    const router = useRouter();
    const path = router.pathname;

    const links = [
        { title: 'Indexes', url: '/admin/indexes' },
        { title: 'Organizations', url: '/admin/organizations' },
        { title: 'Users', url: '/admin/users' },
        { title: 'Instruments', url: '/admin/instruments' },
        { title: 'Benchmark Indexes', url: '/admin/benchmark-indexes' },
        { title: 'Stats', url: '/admin/stats' },
        { title: 'System Variables', url: '/admin/system-variables' },
        { title: 'Feature Flags', url: '/admin/feature-flags' },
        { title: 'Compare (Beta)', url: '/admin/compare' },
        { title: 'Analyst Theme Requests', url: '/admin/analyst-theme-requests' },
        { title: 'Instrument Analysis Requests', url: '/admin/instrument-analysis-requests' },
        { title: 'Analyst Open Requests', url: '/admin/analyst-open-requests' },
        { title: 'Analyst Access', url: '/admin/analyst-access' },
        { title: 'Activity Events', url: '/admin/activity-events' },
        { title: 'Instrument Data Manual Active Rules', url: '/admin/instrument-data-manual-active-rules' },
        { title: 'Tags', url: '/admin/tags' },
        { title: 'Instrument Articles', url: '/admin/instrument-articles' },
        { title: 'Instrument News', url: '/admin/instrument-news' },
        { title: 'Instrument Evaluation', url: '/admin/instrument-evaluation' },
        { title: 'Instrument Evaluation Requests', url: '/admin/instrument-evaluation-requests' },
        { title: 'Instrument Evaluation Comps', url: '/admin/instrument-evaluation-comps' },
    ];

    return (
        <div className="flex-1 flex flex-col p-6 bg-brand-danger text-white justify-start w-48">
            <Link href="/">
                <Cube
                    color="danger"
                    size="sm"
                    className="mb-12"
                />
            </Link>
            <ul>
                {links.map((item, index) => {
                    return (
                        <li
                            key={`item-${index}`}
                            className="uppercase mb-6"
                        >
                            <Link
                                href={item.url}
                                className={cn('text-white visited:text-white hover:text-brand-teal no-underline', {
                                    'font-brand-bold text-brand-chartreuse visited:text-brand-chartreuse':
                                        path.includes(item.url),
                                })}
                            >
                                {item.title}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default AdminNavigation;
