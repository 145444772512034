import { useRouter } from 'next/router';
import React, { Children, Ref, useRef } from 'react';
import { useEffect, useState } from 'react';

import AdminNavigation from '@/components/admin/navigation';
import AnalystFooter from '@/components/analyst/footer';
import { CurrentOrganizationSelector } from '@/components/global/current-organization-selector';
import Footer from '@/components/global/footer';
import { useGlobalState } from '@/components/global/global-state';
import MainFooter from '@/components/global/main-footer';
import { MainHeader, MainHeaderWithIntersection } from '@/components/global/main-header';
import Meta from '@/components/global/meta';
import SiteHeader from '@/components/global/site-header';
import IndexBuilderNav from '@/components/index-builder/navigation';
import { IndexBuilderContextProvider } from '@/components/index-builder/useIndexBuilderContext';

type Props = {
    children: React.ReactNode;
    hideNavigation?: boolean;
};

export const EmptyLayout = ({ children }: Props) => {
    return (
        <>
            <Meta />
            <main id="root">{children}</main>
        </>
    );
};

export const StandardLayout = ({ children }: Props) => {
    return (
        <>
            <Meta />
            <CurrentOrganizationSelector />
            <div className="flex flex-col min-h-screen justify-between">
                <SiteHeader />
                <main
                    id="root"
                    className="flex flex-col h-full flex-grow"
                >
                    <div>{children}</div>
                </main>
                <Footer />
            </div>
        </>
    );
};

export const AdminLayout = ({ children }: Props) => {
    const router = useRouter();
    const { globalState } = useGlobalState();

    if (globalState.currentUserLoading) {
        return <></>;
    } else if (!globalState.currentUser?.isThematicAdmin) {
        router.push('/');
        return <></>;
    }

    return (
        <>
            <Meta />
            <div className="min-h-screen flex items-stretch">
                <AdminNavigation />
                <main
                    id="root"
                    className="w-full"
                >
                    {children}
                </main>
            </div>
        </>
    );
};

export const IndexBuilderLayout = ({ children }: Props) => {
    const router = useRouter();

    const [rawBuilderIndexId, setRawBuilderIndexId] = useState<string>();

    useEffect(() => {
        if (!router.isReady) return;

        setRawBuilderIndexId(router.query.builderIndexId as string);
    }, [router.isReady, router.query]);

    // Route parameters are not immediately available - https://stackoverflow.com/questions/61040790/userouter-withrouter-receive-undefined-on-query-in-first-render
    // Since this is relevant to a Provider component, not sure how to use `useEffect` + `isReady` as recommended without hiding the entire page/layout.
    if (!rawBuilderIndexId) {
        return <></>;
    }

    return (
        <IndexBuilderContextProvider rawBuilderIndexId={rawBuilderIndexId as string}>
            <>
                <Meta />
                <div className="flex flex-col h-screen">
                    <SiteHeader hideLinks={true} />
                    <div className="flex flex-grow">
                        <IndexBuilderNav />
                        <main
                            id="root"
                            className="w-full overflow-y-auto h-full"
                        >
                            {children}
                        </main>
                    </div>
                </div>
            </>
        </IndexBuilderContextProvider>
    );
};

export const AnalystLayout = ({ children }: Props) => {
    return (
        <>
            <Meta />
            <div className="flex flex-col min-h-screen">
                <SiteHeader analystLinkOnly={true} />
                <main id="root">{children}</main>
                <AnalystFooter />
            </div>
        </>
    );
};

export interface ChildrenProps {
    intersectionRef: Ref<HTMLDivElement>;
    children: React.ReactNode;
}

export const RedesignLayout = ({ children, hideNavigation }: Props) => {
    const intersectionRef = useRef(null);
    // Need to pass
    const clonedChildren = Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement<ChildrenProps>, { intersectionRef }, child)
    );

    return (
        <>
            <Meta />
            <div className="pb-24 overflow-x-hidden">
                <CurrentOrganizationSelector />

                <MainHeaderWithIntersection
                    hideNavigation={hideNavigation}
                    condenseLogoOnScroll={true}
                    className="top-5"
                />
                <main id="root">{clonedChildren}</main>
                <MainFooter />
            </div>
        </>
    );
};

export const NewLayoutNoCondensedLogo = ({ children, hideNavigation }: Props) => {
    const intersectionRef = useRef(null);
    // Need to pass
    const clonedChildren = Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement<ChildrenProps>, { intersectionRef }, child)
    );

    return (
        <>
            <Meta />
            <div className="pb-24 overflow-x-hidden">
                <CurrentOrganizationSelector />

                <MainHeader
                    hideNavigation={hideNavigation}
                    className="top-5"
                />
                <main id="root">{clonedChildren}</main>
                <MainFooter />
            </div>
        </>
    );
};
